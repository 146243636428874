<template>
  <div class="grid" v-if="data">
    <div class="bg-nColorHalfWhite px-10 pt-4">
      <div class="flex justify-between items-center">
        <div>
          <img
            src="https://playda.s3.ap-south-1.amazonaws.com/playda-logos/playda_logo.webp"
            width="125"
            height="55"
            alt="Playda Logo"
          />
        </div>
        <div class="flex gap-4 text-nColorOrange font-InterSemiBold">
          <a :href="marketingURL">Know more about Playda!</a>
          <a :href="contactURL">Contact us</a>
          <a @click.prevent="signOut">Sign out</a>
        </div>
      </div>
      <div class="w-1/2 mt-4">
        <div class="border-8 border-nColorLightYellow rounded-3xl">
          <div
            class="border-4 border-nColorYellow rounded-2xl bg-white overflow-visible"
          >
            <div class="flex justify-around" v-if="data.pricing">
              <div class="grid p-4 border-r border-nColorYellow">
                <div
                  :class="[
                    'font-InterBold',
                    'text-xs',
                    'py-1',
                    'rounded-xl',
                    'border',
                    'border-nColorBGLightGray',
                    getBackgroundColorClass(0),
                    getTextColorClass(0),
                  ]"
                  class="font-InterBold text-xs py-1 rounded-xl border border-nColorBGLightGray w-28 text-center"
                >
                  {{ data.pricing[0].name }}
                </div>
                <div v-if="data.features">
                  <div>
                    <div
                      v-for="feature in data.features"
                      :key="feature.slug"
                      class="my-7"
                    >
                      <p class="font-InterMedium text-xs text-nColorLightGray">
                        {{ feature.name }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-for="(pricing, index) in data.pricing"
                :key="index"
                class="grid text-center cursor-pointer px-10 pt-4 border-l-4 border-4 border-transparent"
                @click.prevent="selectPricing(index)"
              >
                <div class="flex justify-center font-InterBold">
                  <span v-if="isNumber(pricing.price)">&#8377;</span
                  >{{ pricing.price }}
                </div>
                <div>
                  <div
                    v-for="feature in data.features"
                    :key="feature.slug"
                    class="my-6 flex justify-center"
                  >
                    <div v-if="pricing[feature.slug] === true">
                      <img
                        src="https://playda.s3.ap-south-1.amazonaws.com/diy/pricing/pricing_yes.webp"
                        width="20"
                        height="20"
                        alt="Pricing Yes"
                      />
                    </div>
                    <div v-else-if="pricing[feature.slug] === false">
                      <img
                        src="https://playda.s3.ap-south-1.amazonaws.com/diy/pricing/pricing_no.webp"
                        width="20"
                        height="20"
                        alt="Pricing No"
                      />
                    </div>
                    <div v-else>{{ pricing[feature.slug] }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-1/2 p-4 font-InterSemiBold text-xs">
        <div
          class="flex justify-between items-center border-b border-nColorLightGray py-2"
        >
          <div>Subtotal</div>
          <div>
            <div class="flex justify-center font-InterBold">
              <span v-if="isNumber(data.pricing[0].price)">&#8377;</span
              >{{ data.pricing[0].price }}
            </div>
          </div>
        </div>
        <div
          class="flex justify-between items-center border-b border-nColorLightGray py-2"
        >
          <div>Taxes</div>
          <div>
            <div class="flex justify-center font-InterBold">
              <span>&#8377;</span>0
            </div>
          </div>
        </div>
        <div
          class="flex justify-between items-center border-b border-nColorLightGray py-2"
        >
          <div>Total</div>
          <div>
            <div class="flex justify-center font-InterBold">
              <span v-if="isNumber(data.pricing[0].price)">&#8377;</span
              >{{ data.pricing[0].price }}
            </div>
          </div>
        </div>
      </div>
      <div class="flex">
        <div class="w-1/2"></div>
        <div class="w-1/2 flex justify-end">
          <button
            @click.prevent="changePlan()"
            class="border border-nColorDarkGray rounded-3xl px-4 text-nColorDarkGray text-center py-1 uppercase text-xl font-ZuumeSemiBold tracking-wider focus:outline-none"
          >
            <span> Change Plan </span>
          </button>
          <!-- <button
            @click.prevent="payNow()"
            class="bg-nColorDarkGray rounded-3xl px-4 text-white text-center py-1 uppercase text-xl font-ZuumeSemiBold tracking-wider focus:outline-none ml-2"
          >
            <span> Pay Now </span>
          </button> -->
          <button
            @click="initiatePayment"
            class="bg-nColorDarkGray rounded-3xl px-4 text-white text-center py-1 uppercase text-xl font-ZuumeSemiBold tracking-wider focus:outline-none ml-2"
          >
            Pay now
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import { ref } from "vue";
import TokenService from "@/common/token.service";
import { useToast } from "vue-toastification";

const toast = useToast();

export default {
  name: "Desktop",
  components: {},
  data() {
    return {
      data: null,
      selectedPricing: 0,
      isSubmitLoading: false,
      marketingURL: process.env.VUE_APP_MARKETING_URL,
      contactURL: process.env.VUE_APP_CONTACT_URL,
      scriptUrl: process.env.VUE_APP_RAZORPAY_URL,
      receiptId: null,
      orderDetails: null,
      finalPayment: null,
    };
  },
  created() {
    ApiService.get(apiResource.getPricingDetailsFromSlug, {
      slug: TokenService.getToken("pricing_plan"),
    }).then((data) => {
      this.data = data.data.data;
    });
  },
  methods: {
    async loadRazorpayScript() {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = this.scriptUrl;
        script.onload = () => {
          resolve(true);
        };
        script.onerror = () => {
          reject(new Error("Failed to load Razorpay script"));
        };
        document.body.appendChild(script);
      });
    },
    async fetchOrderDetails() {
      this.finalPayment = this.data.pricing[0].price * 100;
      await ApiService.post(apiResource.gameJams.createRazorpayOrder, {
        amt: this.finalPayment,
        package: TokenService.getToken("pricing_plan"),
      }).then((data) => {
        this.orderDetails = data.data.data;
        this.receiptId = this.orderDetails.receipt_id;
        console.log(this.orderDetails);
      });
    },
    async initiatePayment() {
      try {
        await this.loadRazorpayScript();
        await this.fetchOrderDetails();
        const options = {
          key: process.env.VUE_APP_RAZORPAY_KEY_ID,
          amount: this.finalPayment,
          currency: "INR",
          name: "Playda!",
          description: "Playda Game Jam Package",
          order_id: this.orderDetails.razorpay_order_response.id,
          image:
            "https://playda.s3.ap-south-1.amazonaws.com/playda-logos/playda_logo.webp",
          handler: (response) => {
            this.handlePaymentSuccess(response);
          },
          prefill: {
            name: this.orderDetails.diy_user_name,
            email: this.orderDetails.diy_user_email,
            contact: "",
          },
        };
        const razorpayInstance = new window.Razorpay(options);
        razorpayInstance.open();
      } catch (error) {
        console.log(error.message);
      }
    },
    handlePaymentSuccess(response) {
      console.log("Payment Successfull", response);
      ApiService.post(apiResource.gameJams.confirmPayment, {
        receipt_id: this.receiptId,
        payment_response: response,
      }).then(() => {
        toast.success("Payment received successfully!", { timeout: 2000 });
        setTimeout(() => {
          this.$router.replace({
            name: "Dashboard",
          });
        }, 2000);
      });
    },
    getBackgroundColorClass(index) {
      const classes = [
        "bg-diy-blue-bg",
        "bg-diy-green-bg",
        "bg-diy-pink-bg",
        "bg-diy-purple-bg",
      ];
      return classes[index % classes.length];
    },
    getTextColorClass(index) {
      const classes = [
        "text-diy-blue-text",
        "text-diy-green-text",
        "text-diy-pink-text",
        "text-diy-purple-text",
      ];
      return classes[index % classes.length];
    },
    selectPricing(index) {
      this.selectedPricing = index;
    },
    getStarted() {
      this.isSubmitLoading = true;
      const pricingArray = ref(this.data.pricing);
      var pricingDetails = pricingArray.value[this.selectedPricing];
      if (pricingDetails.action === "form") {
        window.location.replace(this.contactURL);
      } else {
        TokenService.saveToken("pricing_plan", pricingDetails.slug);
        this.$router.push({
          name: "Register",
        });
      }
    },
    isNumber(value) {
      return typeof value === "number";
    },
    signOut() {
      this.$store
        .dispatch("signOut")
        .then(() => {
          this.$router.replace({ name: "Login" });
        })
        .catch(() => {});
    },
    changePlan() {
      this.$router.push({ name: "Pricing" });
    },
  },
  computed: {},
};
</script>
  
<style scoped lang="postcss">
.pricing-selected {
  @apply bg-nColorLightYellow border-l-4 border-r-4 border-nColorYellow;
}
</style>
  