<template>
  <div class="grid">
    <Mobile v-if="isMobile" />
    <Desktop v-else />
  </div>
</template>
    
    <script>
import Desktop from "@/components/Payment/Desktop.vue";
import Mobile from "@/components/Payment/Mobile.vue";

export default {
  name: "Payment",
  components: { Desktop, Mobile },
  data() {
    return {
      isMobile: false,
    };
  },
  created() {},
  mounted() {
    this.isMobile = window.innerWidth <= 768; // Adjust the breakpoint as needed
    window.addEventListener("resize", this.handleResize);
  },
  computed: {},
  methods: {
    handleResize() {
      this.isMobile = window.innerWidth <= 768; // Adjust the breakpoint as needed
    },
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
    
<style scoped></style>
    